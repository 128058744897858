import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import FallbackSpinner from "./components/common/FallbackSpinner";

// Private Routes
const Home = React.lazy(() => import("./containers/Home"));
const Stores = React.lazy(() => import("./containers/store/Stores"));
const StoreDetails = React.lazy(() =>
  import("./containers/store/StoreDetails")
);
const Profile = React.lazy(() => import("./containers/Profile"));
const ChangePassword = React.lazy(() => import("./containers/ChangePassword"));
const PrivateRoute = React.lazy(() => import("./containers/PrivateRoute"));
const Login = React.lazy(() => import("./containers/Login"));
const ForgotPassword = React.lazy(() => import("./containers/ForgotPassword"));

const publicRoutes = [
  {
    path: "/connexion",
    component: Login,
    exact: true,
  },
  {
    path: "/mot-de-passe-oublie",
    component: ForgotPassword,
  },
];

const privateRoutes = [
  //HOME
  {
    path: "/accueil/profil",
    component: Profile,
    exact: true,
  },
  {
    path: "/accueil/changer-mot-de-passe",
    component: ChangePassword,
    exact: true,
  },

  {
    path: "/restaurants/pages/:page",
    component: Stores,
    exact: true,
  },

  {
    path: "/restaurants/details/:id/:scrollToProducts?",
    component: StoreDetails,
    exact: true,
  },

  {
    path: "/(|accueil)/",
    component: Home,
    exact: true,
  },
];

class Routes extends React.PureComponent {
  render() {
    return (
      <React.Suspense fallback={<FallbackSpinner />}>
        <HashRouter>
          <Switch>
            {publicRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            <PrivateRoute>
              <Switch>
                {privateRoutes.map((route) => (
                  <Route key={route.path} {...route} />
                ))}
              </Switch>
            </PrivateRoute>
          </Switch>
        </HashRouter>
      </React.Suspense>
    );
  }
}

export default Routes;
