export default {
  Query: {
    isLoggedIn: (_, {}, { getCacheKey }) =>
      getCacheKey({ __typename: "IsLoggedIn" }),
  },
  Mutation: {
    setToken: (_, { token }, { cache }) => {
      const bearerToken = token ? `Bearer ${token}` : null;
      localStorage.setItem("token", bearerToken);
      cache.writeData({
        data: {
          isLoggedIn: {
            __typename: "IsLoggedIn",
            isLoggedIn: !!token,
          },
        },
      });
      return null;
    },
  },
};
